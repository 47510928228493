<template>
  <el-dialog @close='close' v-model='dialogFormVisible' :title='title'>
    <el-form :model='form'>
      <el-form-item label='真实姓名' :label-width='formLabelWidth'>
        <el-input v-model='form.name' autocomplete='off' />
      </el-form-item>
      <el-form-item label='联系电话' :label-width='formLabelWidth'>
        <el-input v-model='form.phone' autocomplete='off' />
      </el-form-item>
      <el-form-item label='反馈情况' :label-width='formLabelWidth'>
        <el-input
          :rows='3'
          type='textarea'
          v-model='form.remark'
          autocomplete='off'
        />
      </el-form-item>
    </el-form>
    <div class='box'>
      <el-upload
        v-if='isShow'
        :ref='uploadRef'
        class='upload-demo'
        :headers='uploadData'
        :action="uploadUrl + '/file/upload'"
        multiple
        :limit='3'
        accept='image/*'
        :on-success='onSuccess'
        :on-remove='onRemove'
      >
        <el-button type='primary'>点击上传图片附件</el-button>
        <template #tip>
          <!-- <div class="el-upload__tip">jpg/png 格式</div> -->
        </template>
      </el-upload>
    </div>
    <template #footer>
      <span class='dialog-footer'>
        <el-button @click='dialogFormVisible = false'>取消</el-button>
        <el-button type='primary' @click='confirm()'> 确定 </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import {
  ref,
  onMounted,
  reactive,
  nextTick,
  defineExpose,
  defineEmits,
  defineProps
} from 'vue'
import { useStore } from 'vuex'

const isShow = ref(true)
const uploadRef = ref()
const store = useStore()
const props = defineProps(['title'])
const title = ref()
title.value = props.title
// console.log(store.getters.token)
// 文件上传
// let uploadUrl = ref(process.env.VUE_APP_URL_RENT_BASE)
const uploadUrl = ref('/upload')

const uploadData = {
  'X-Access-Token': store.getters.token
}
// const fileList = ref([
//   {
//     name: 'element-plus-logo.svg',
//     url: 'https://element-plus.org/images/element-plus-logo.svg'
//   },
//   {
//     name: 'element-plus-logo2.svg',
//     url: 'https://element-plus.org/images/element-plus-logo.svg'
//   }
// ])
const fileList = ref([])

function onSuccess(e) {
  console.log(e)
  fileList.value.push(e.result)
  console.log(fileList.value)
}

function onRemove(e) {
  console.log(e)
}

const emit = defineEmits(['response'])
const dialogFormVisible = ref(false)
const formLabelWidth = '100px'
const form = reactive({
  name: '',
  phone: '',
  remark: ''
})

function openModel() {
  dialogFormVisible.value = true
}

function closeModel() {
  dialogFormVisible.value = false
}

// 确定
function confirm() {
  dialogFormVisible.value = false
  isShow.value = false

  emit('response', { form, fileList: fileList.value })
  setTimeout(() => {
    isShow.value = true

    form.name = ''
    form.phone = ''
    form.remark = ''
    fileList.value = []
    nextTick(() => {
    })
  }, 100)
}

function close(e) {
  isShow.value = false
  setTimeout(() => {
    isShow.value = true
    form.name = ''
    form.phone = ''
    form.remark = ''
    fileList.value = []
  }, 100)
}

defineExpose({
  openModel,
  closeModel
})
</script>
<style scoped>
.box {
  padding-left: 100px;
}
</style>
