<template>
  <div class="list-wrapper">
    <el-row class="type-search-warp" justify="space-between">
      <el-col class="type-warp" :span="12">
        <el-radio-group v-model="type" size="large" @change="listFilter">
          <el-radio-button label="企业名录" />
          <el-radio-button label="成交量排行" />
          <el-radio-button label="挂牌量排行" />
        </el-radio-group>
      </el-col>
      <el-col :span="8">
        <el-input v-model="companyName" placeholder="请输入企业名称" clearable>
          <template #append>
            <el-button @click="search">
              <el-icon :size="20" :color="color">
                <Search />
              </el-icon>
            </el-button>
          </template>
        </el-input>
      </el-col>
    </el-row>

    <div class="sub-type-warp" v-if="type == '企业名录'" @click="typeChange">
      <el-radio-group v-model="subType" size="middle">
        <el-radio-button label="租赁企业" />
        <el-radio-button label="经纪机构" />
      </el-radio-group>
    </div>

    <div
      class="sub-type-warp"
      v-if="type == '企业名录' && subType == '经纪机构'"
      @change="rentSubChange"
    >
      <el-radio-group v-model="rentSubType" size="middle">
        <el-radio-button label="加盟店" />
        <el-radio-button label="门店" />
      </el-radio-group>
    </div>
    <!-- 企业名录 -->
    <el-table
      :data="tableData"
      width="100%"
      :header-cell-style="tableTr"
      v-if="type == '企业名录'"
    >
      <el-table-column
        label="序号"
        type="index"
        width="50"
        align="center"
        :index="indexMethod"
      >
      </el-table-column>
      <el-table-column label="企业名称" align="center">
        <template #default="scope">
          <el-popover
            placement="right"
            width="180"
            :ref="`popover-${scope.$index}`"
            trigger="hover"
          >
            <template #reference>
              <el-button
                style="border-width: 0; padding: 0; color: #409eff"
                @click="detail(scope.row.fullName)"
              >
                {{ scope.row.fullName }}
              </el-button>
            </template>
            <qrcode-vue
              :value="`https://grid.nnkcy.com/pages/qrcode/company?id=${scope.row.orgId}`"
              :size="qrcodeSize"
              level="H"
            />
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        prop="chairMan"
        label="法人代表"
        width="100"
        align="center"
      />
      <el-table-column
        prop="address"
        label="机构地址"
        align="center"
        min-width="150"
      >
        <template #default="scope">
          {{ scope.row.address ? scope.row.address : scope.row.officeAddress }}
        </template>
      </el-table-column>
      <el-table-column
        prop="businessSituation"
        label="营业状态"
        width="120"
        align="center"
      />
      <el-table-column
        prop="businessSituation"
        label="信用分值"
        width="120"
        align="center"
      >
        <template #default="{ row }">
          <span v-if="row.score">
            {{ row.score + '分' }}
          </span>
          <span v-else>暂无</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 成交量排行  -->
    <el-table
      :data="tableData"
      width="100%"
      :cell-style="nameStyle"
      :header-cell-style="tableTr"
      v-if="type == '成交量排行'"
    >
      <el-table-column label="排名" width="150" align="center">
        <template #default="scope">
          <span v-if="page < 2 && scope.$index + 1 === 1">
            <el-image
              :src="oneImg"
              style="width: 22px; height: 22px"
            ></el-image>
          </span>
          <span v-else-if="page < 2 && scope.$index + 1 === 2">
            <el-image
              :src="twoImg"
              style="width: 22px; height: 22px"
            ></el-image>
          </span>
          <span v-else-if="page < 2 && scope.$index + 1 === 3">
            <el-image
              :src="threeImg"
              style="width: 22px; height: 22px"
            ></el-image>
          </span>
          <span v-else>
            {{ indexMethod(scope.$index) }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="qymc"
        label="企业名称"
        min-width="150"
        align="center"
      >
        <template #default="scope">
          <el-button
            style="border-width: 0; padding: 0; color: #409eff"
            @click="detail(scope.row.qymc)"
          >
            {{ scope.row.qymc }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="total" label="签约量" align="center" width="150">
        <template #default="scope">
          <el-tag effect="dark" type="warning" size="middle">
            {{ scope.row.total }}
          </el-tag>
        </template>
      </el-table-column>
    </el-table>
    <!-- 挂牌量排行 -->
    <el-table
      :data="tableData"
      width="100%"
      :cell-style="nameStyle"
      :header-cell-style="tableTr"
      v-if="type == '挂牌量排行'"
    >
      <el-table-column label="排名" width="150" align="center">
        <template #default="scope">
          <span v-if="page < 2 && scope.$index + 1 === 1">
            <el-image
              :src="oneImg"
              style="width: 22px; height: 22px"
            ></el-image>
          </span>
          <span v-else-if="page < 2 && scope.$index + 1 === 2">
            <el-image
              :src="twoImg"
              style="width: 22px; height: 22px"
            ></el-image>
          </span>
          <span v-else-if="page < 2 && scope.$index + 1 === 3">
            <el-image
              :src="threeImg"
              style="width: 22px; height: 22px"
            ></el-image>
          </span>
          <span v-else>
            {{ indexMethod(scope.$index) }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="qymc"
        label="企业名称"
        min-width="150"
        align="center"
      >
        <template #default="scope">
          <el-button
            style="border-width: 0; padding: 0; color: #409eff"
            @click="detail(scope.row.qymc)"
          >
            {{ scope.row.qymc }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="total" label="挂牌量" align="center" width="150">
        <template #default="scope">
          <el-tag effect="dark" type="warning" size="middle">
            {{ scope.row.total }}
          </el-tag>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: right; margin-top: 20px">
      <el-pagination
        background
        class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[5, 10, 50, 100, 200]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script setup>
import {
  getEnterpriseList,
  getEnterpriseHtRank,
  getEnterpriseUpRank
} from '@/api/enterprise'
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import QrcodeVue from 'qrcode.vue'

const tableData = ref([])
const total = ref(0)
const etype = ref(2)
const page = ref(1)
const size = ref(10)
const companyName = ref()
const keyword = ref()
const type = ref('企业名录')
const subType = ref('租赁企业')
const rentSubType = ref('')
const router = useRouter()
const oneImg = require('@/assets/one.png')
const twoImg = require('@/assets/two.png')
const threeImg = require('@/assets/three.png')
/**
 * 获取企业列表信息
 */
const getList = async (clear) => {
  const result = await getEnterpriseList({
    type: etype.value,
    pageNo: page.value,
    pageSize: size.value,
    companyName: companyName.value
  })
  tableData.value = []
  total.value = 0
  tableData.value = result.records
  total.value = result.total
  if (clear) {
    companyName.value = ''
  }
}
/**
 * 获取成交量排行
 */
const getHtRank = async (clear) => {
  const result = await getEnterpriseHtRank({
    pageNo: page.value,
    pageSize: size.value,
    qymc: companyName.value
  })
  tableData.value = []
  total.value = 0
  tableData.value = result.records
  total.value = result.total
  if (clear) {
    companyName.value = ''
  }
}
/**
 * 获取挂牌量排行
 */
const getUpRank = async (clear) => {
  const result = await getEnterpriseUpRank({
    pageNo: page.value,
    pageSize: size.value,
    qymc: companyName.value
  })
  tableData.value = []
  total.value = 0
  tableData.value = result.records
  total.value = result.total
  if (clear) {
    companyName.value = ''
  }
}
onMounted(() => {
  getList()
})

// watchSwitchLang(getEnterpriseList)
// 处理数据不重新加载的问题
// onActivated(getEnterpriseList)

/**
 * size 改变触发
 */
const handleSizeChange = (currentSize) => {
  size.value = currentSize
  if (type.value === '成交量排行') {
    getHtRank()
  } else if (type.value === '挂牌量排行') {
    getUpRank()
  } else {
    getList()
  }
}

/**
 * 页码改变触发
 */
const handleCurrentChange = (currentPage) => {
  page.value = currentPage
  if (type.value === '成交量排行') {
    getHtRank()
  } else if (type.value === '挂牌量排行') {
    getUpRank()
  } else {
    getList()
  }
}
/**
 * 设置表头背景色
 */
const tableTr = () => {
  return 'background:#F8F8F9;color:#000'
}

const nameStyle = ({ row, column, rowIndex, columnIndex }) => {
  if (columnIndex === 1) {
    return 'color:#409eff;font-size:14px;font-weight:500;'
  } else if (columnIndex === 2) {
    return 'color:#333;font-size:14px;font-weight:500;'
  } else {
    return 'color:#333;font-size:14px;'
  }
}

/**
 * 二维码生成配置
 */
const qrcodeSize = ref(160)

/**
 * 列表数据过滤
 */
const listFilter = () => {
  companyName.value = ''
  if (type.value === '成交量排行') {
    page.value = 1
    size.value = 10
    getHtRank(true)
  } else if (type.value === '挂牌量排行') {
    size.value = 10
    page.value = 1
    getUpRank(true)
  } else {
    size.value = 10
    page.value = 1
    getList(true)
  }
}

const typeChange = () => {
  companyName.value = ''

  if (subType.value === '租赁企业') {
    etype.value = 2
    console.log(etype.value)
  } else {
    etype.value = 3
  }
  rentSubType.value = ''
  getList(true)
}

const rentSubChange = () => {
  companyName.value = ''

  if (rentSubType.value === '加盟店') {
    etype.value = 4
  } else if (rentSubType.value === '门店') {
    etype.value = 5
  }
  getList(true)
}
/**
 * 搜索
 */
const search = () => {
  // companyName.value = keyword.value
  if (type.value === '成交量排行') {
    getHtRank()
  } else if (type.value === '挂牌量排行') {
    getUpRank()
  } else {
    getList()
  }
}
/**
 * 企业详情
 */
const detail = (fname) => {
  // router.push({
  //   name: 'enterpriseDetail',
  //   params: { orgId }
  // })
  router.push({
    path: `/enterpriseInfo/detail/${fname}`
  })
}
/**
 * 排名方法
 */
const indexMethod = (index) => {
  const r = index + 1
  if (page.value > 1) {
    return (page.value - 1) * size.value + r
  } else {
    return r
  }
}
</script>

<style lang="scss">
.list-wrapper {
  padding: 10px;
  border: solid 1px #e4e7ed;
  font-size: 13px;

  .type-warp {
    margin: 0px 0 10px;
  }

  .sub-type-warp {
    margin: 0px 0 10px;
  }
}

.compay-name {
  .qrcode-img-warp {
    left: 0;
    top: 0;
    width: 420 rpx;
    padding: 3%;
    border: 1px #e2e2e2 solid;
    box-shadow: 2 rpx 2 rpx 5 rpx #666;
    z-index: 888;
    background-color: #fff;
    height: 420 rpx;
    position: absolute;
    display: none;
  }

  &:hover {
    .qrcode-img-warp {
      display: block;
    }
  }
}
</style>
