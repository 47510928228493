import request from '@/utils/request'

/**
 * 新增企业反映情况
 */
export const tCreditEnterpriseRef = (params) => {
  return request({
    url: '/tCreditEnterpriseRef',
    method: 'POST',
    data: params
  })
}
/**
 * 新增门店反映情况
 */
export const tCreditStoreRef = (params) => {
  return request({
    url: '/tCreditStoreRef/add',
    method: 'POST',
    data: params
  })
}
/**
 * 新增经纪人反映情况
 */
export const tCreditEmployerRef = (params) => {
  return request({
    url: 'tCreditEmployerRef',
    method: 'POST',
    data: params
  })
}
