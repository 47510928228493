<template>
  <div class="list-wrapper">
    <div class="title">
      <h2>门店信息</h2>

      <el-button @click="add" type="primary">门店信息采集情况反应</el-button>
    </div>
    <div class="qr-img">
      <qrcode-vue
        :value="`https://grid.nnkcy.com/pages/qrcode/store?id=${detailData.orgId}`"
        :size="qrcodeSize"
        level="H"
      />
    </div>

    <table class="detail-table" width="100%">
      <tbody>
        <tr>
          <td class="t-title">门店名称</td>
          <td class="d-content">{{ detailData.fullName }}</td>
          <td class="t-title">授权单位</td>
          <td class="d-content">{{ detailData.parentName }}</td>
        </tr>
        <tr>
          <td class="t-title">授权有效期</td>
          <td class="d-content">
            {{ detailData.certStartDate }}-{{ detailData.certStopDate }}
          </td>
          <td class="t-title">所属网格区域</td>
          <td class="d-content">{{ detailData.organizeType }}</td>
        </tr>

        <tr>
          <td class="t-title">是否为加盟店</td>
          <td class="d-content">{{ detailData.organizeType }}</td>
          <td class="t-title">是否续存</td>
          <td class="d-content">{{ detailData.divisionId }}</td>
        </tr>
        <tr>
          <td class="t-title">门店地址</td>
          <td class="d-content" colspan="3">{{ detailData.officeAddress }}</td>
        </tr>
      </tbody>
    </table>
    <h2 style="margin-top: 10px">企业信息</h2>
    <table class="compay-table" width="100%">
      <tbody>
        <tr>
          <td class="d-title">门店企业名称</td>
          <td class="d-content">{{ compayData.fullName }}</td>
          <td class="d-title">企业类型</td>
          <td class="d-content">{{ compayData.orgIdType }}</td>
          <td class="d-title">单位性质</td>
          <td class="d-content">{{ compayData.type }}</td>
        </tr>
        <tr>
          <td class="d-title">注册县区</td>
          <td class="d-content">{{ compayData.divisionId }}</td>
          <td class="d-title">证件类型</td>
          <td class="d-content">{{ compayData.idType }}</td>
          <td class="d-title">证件号</td>
          <td class="d-content">{{ compayData.tcol }}</td>
        </tr>

        <tr>
          <td class="d-title">法定代表</td>
          <td class="d-content">{{ compayData.chairMan }}</td>
          <td class="d-title">注册资本(万元)</td>
          <td class="d-content">{{ compayData.capital }}</td>
          <td class="d-title">成立时间</td>
          <td class="d-content">{{ compayData.regDate }}</td>
        </tr>
        <tr>
          <td class="d-title">营业期限</td>
          <td class="d-content">{{ compayData.certDate }}</td>
          <td class="d-title">注册地址</td>
          <td class="d-content" colspan="3">{{ compayData.address }}</td>
        </tr>
        <tr>
          <td class="d-title">营业状态</td>
          <td class="d-content">{{ compayData.businessSituation }}</td>
          <td class="d-title">经营范围</td>
          <td class="d-content" colspan="3">{{ compayData.scope }}</td>
        </tr>
      </tbody>
    </table>
    <el-row>
      <otherInfo
        v-if="detailData.parentOrgId"
        :orgId="detailData.parentOrgId"
        :fullname="detailData.parentName"
      ></otherInfo>
    </el-row>
  </div>
  <!-- 添加模板 -->
  <addModel
    title="门店信息采集反馈"
    @response="response"
    ref="addModelRef"
  ></addModel>
</template>

<script setup>
import { getShopDetail, getEnterpriseDetail } from '@/api/enterprise'
import { ref, onMounted, nextTick } from 'vue'
import { useRoute } from 'vue-router'
import addModel from './addModel.vue'
import QrcodeVue from 'qrcode.vue'
import otherInfo from '@/components/EnterpriseInfoRelated'
import { tCreditStoreRef } from '@/api/situation.js'
import { ElMessage } from 'element-plus'

let addModelRef = ref()
// 打开企业信息采集情况反应
function add() {
  nextTick(() => {
    console.log(addModelRef.value.openModel())
  })
}
// 添加表单回执
async function response(e) {
  console.log(detailData.value)
  let obj = {
    attachment: e.fileList,
    auditSteps: 1,
    name: e.form.name,
    phone: e.form.phone,
    situation: e.form.remark,
    storeId: detailData.value.orgId
  }
  try {
    let res = await tCreditStoreRef(obj)
    ElMessage.success('创建成功！')
  } catch (error) {
    ElMessage.success(error)
  }
}

const orgId = ref()
const detailData = ref({})
const compayData = ref({})
const route = useRoute()

const getDetail = async () => {
  const result = await getShopDetail({
    branchId: orgId.value
  })
  detailData.value = result
  getCompayDetail(result.parentName)
}
const getCompayDetail = async (fname) => {
  const result = await getEnterpriseDetail({
    orgName: fname
  })
  compayData.value = result
}
onMounted(() => {
  orgId.value = route.params.id
  console.log(orgId.value)
  getDetail()
})

/**
 * 二维码生成配置
 */
const qrcodeSize = ref(100)
</script>

<style lang="scss">
.title {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}
.list-wrapper {
  position: relative;
  padding: 10px;
  border: solid 1px #e4e7ed;
  font-size: 13px;
  h2 {
    margin: 0px 0 10px;
  }
  .sub-type-warp {
    margin: 0px 0 10px;
  }

  .detail-table {
    border-collapse: collapse;
    margin: 0 auto;
    border: 1px #e4e7ed solid;
    border-width: 1px 0 0 1px;

    .t-title {
      padding-left: 10px;
      line-height: 30px;
      width: 10%;
      background: #f0f7fc;
      font-weight: 600;
      border: 1px #e4e7ed solid;
      border-width: 0 1px 1px 0;
    }
    .d-content {
      line-height: 30px;
      padding: 5px 10px;
      min-width: 350px;
      border: 1px #e4e7ed solid;
      border-width: 0 1px 1px 0;
    }
  }
  .compay-table {
    border-collapse: collapse;
    margin: 0 auto;
    border: 1px #e4e7ed solid;
    border-width: 1px 0 0 1px;

    .d-title {
      padding-left: 10px;
      line-height: 30px;
      width: 10%;
      background: #f0f7fc;
      font-weight: 600;
      border: 1px #e4e7ed solid;
      border-width: 0 1px 1px 0;
    }
    .d-content {
      line-height: 30px;
      padding: 5px 10px;
      min-width: 250px;
      border: 1px #e4e7ed solid;
      border-width: 0 1px 1px 0;
    }
  }
}
.qr-img {
  position: absolute;
  right: 10px;
  top: 60px;
  padding: 10px;
  height: 120px;
  width: 120px;
  z-index: 888;
  border: 1px #e4e7ed solid;
  background-color: #fff;
  box-shadow: 1px 1px 2px #e2e2e2;
}
</style>
