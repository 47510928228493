<template>
  <div class="list-wrapper">
    <div class="title">
      <h1>经纪人信息</h1>
      <el-button @click="add" type="primary">经纪人情况反映</el-button>
    </div>
    <div class="qr-img">
      <!-- :value="`https://grid.nnkcy.com/pages/qrcode/staff?id=${detailData.orgId}`" -->
      <qrcode-vue
        :value="`https://grid.nnkcy.com/pages/qrcode/staff?id=${userId}`"
        :size="qrcodeSize"
        level="H"
      />
    </div>

    <table class="detail-table" width="100%">
      <tbody>
        <tr>
          <td
            rowspan="6"
            style="
              text-align: center;
              border: 1px #e4e7ed solid;
              border-width: 0 1px 1px 0;
              mix-width: 160px;
            "
          >
            <el-image
              style="width: 160px"
              src="http://img0.baidu.com/it/u=1580287918,1662928404&fm=253&app=138&f=JPEG?w=500&h=586"
            >
              <template #error>
                <div class="image-slot">
                  <el-icon><icon-picture /></el-icon>
                </div>
              </template>
            </el-image>
          </td>
        </tr>
        <tr>
          <td class="t-title">姓名</td>
          <td class="d-content" colspan="3">{{ detailData.name }}</td>
        </tr>

        <tr>
          <td class="t-title">就职企业</td>
          <td class="d-content">{{ compayData.fullName }}</td>
          <td class="t-title">职务</td>
          <td class="d-content">{{ detailData.post }}</td>
        </tr>
        <tr>
          <td class="t-title">联系方式</td>
          <td class="d-content">{{ detailData.mobileNo }}</td>
          <td class="t-title">邮箱</td>
          <td class="d-content">{{ detailData.email }}</td>
        </tr>
        <tr>
          <td class="t-title">资格证号</td>
          <td class="d-content">{{ detailData.practisingCertificateNo }}</td>
          <td class="t-title">资格证类型</td>
          <td class="d-content">无</td>
        </tr>
        <tr>
          <td class="t-title">是否有资格证书</td>
          <td class="d-content" colspan="3">
            <span v-if="detailData.practisingCertificateNo">有</span>
            <span v-else>无</span>
          </td>
        </tr>
      </tbody>
    </table>
    <el-row>
      <otherInfo
        v-if="compayData.orgId"
        :orgId="compayData.orgId"
        :fullname="compayData.fullName"
      ></otherInfo>
    </el-row>
  </div>
  <!-- 添加模板 -->
  <addModel
    title="经纪人采集反馈"
    @response="response"
    ref="addModelRef"
  ></addModel>
</template>

<script setup>
import { ElMessage } from 'element-plus'
import { getAgentDetail, getIdEnterpriseDetail } from '@/api/enterprise'
import { ref, onMounted, nextTick } from 'vue'
import { useRoute } from 'vue-router'
import QrcodeVue from 'qrcode.vue'
import otherInfo from '@/components/EnterpriseInfoRelated'
import addModel from './addModel.vue'
import { tCreditEmployerRef } from '@/api/situation.js'

let addModelRef = ref()
// 打开企业信息采集情况反应
function add() {
  nextTick(() => {
    console.log(addModelRef.value.openModel())
  })
}
// 添加表单回执
async function response(e) {
  console.log(detailData.value)
  let obj = {
    attachment: e.fileList,
    auditSteps: 1,
    name: e.form.name,
    phone: e.form.phone,
    situation: e.form.remark,
    agentId: detailData.value.id
  }
  try {
    let res = await tCreditEmployerRef(obj)
    ElMessage.success('创建成功！')
  } catch (error) {
    ElMessage.success(error)
  }
}

const userId = ref()
const detailData = ref({})
const compayData = ref({})
const route = useRoute()

const getDetail = async () => {
  const result = await getAgentDetail({
    userId: userId.value
  })
  detailData.value = result
  console.log(result)
  getCompayDetail(result.orgId)
}
const getCompayDetail = async (orgId) => {
  const result = await getIdEnterpriseDetail({
    orgId: orgId
  })
  compayData.value = result
}
onMounted(() => {
  userId.value = route.params.id

  getDetail()
})

/**
 * 二维码生成配置
 */
const qrcodeSize = ref(100)
</script>

<style lang="scss">
.title {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}
.list-wrapper {
  position: relative;
  padding: 10px;
  border: solid 1px #e4e7ed;
  font-size: 13px;
  h1 {
    margin: 0px 0 10px;
  }
  .sub-type-warp {
    margin: 0px 0 10px;
  }
}
.detail-table {
  border-collapse: collapse;
  margin: 0 auto;
  border: 1px #e4e7ed solid;
  border-width: 1px 0 0 1px;

  .t-title {
    padding-left: 10px;
    line-height: 30px;
    width: 10%;
    background: #f0f7fc;
    font-weight: 600;
    border: 1px #e4e7ed solid;
    border-width: 0 1px 1px 0;
  }
  .d-content {
    padding-left: 10px;
    line-height: 28px;
    min-width: 350px;
    border: 1px #e4e7ed solid;
    border-width: 0 1px 1px 0;
  }
}

.qr-img {
  position: absolute;
  right: 10px;
  top: 60px;
  padding: 10px;
  height: 120px;
  width: 120px;
  z-index: 888;
  border: 1px #e4e7ed solid;
  background-color: #fff;
  box-shadow: 1px 1px 2px #e2e2e2;
}
</style>
