<template>
  <div class='list-wrapper'>
    <div class='title'>
      <h1>企业信息</h1>
      <el-button @click='add' type='primary'>企业信息采集情况反应</el-button>
    </div>
    <div class='qr-img'>
      <qrcode-vue
        :value='`https://grid.nnkcy.com/pages/qrcode/company?id=${detailData.orgId}`'
        :size='qrcodeSize'
        level='H'
      />
    </div>

    <table class='detail-table' width='100%'>
      <tbody>
      <tr>
        <td class='t-title'>企业名称</td>
        <td class='d-content'>{{ detailData.fullName }}</td>
        <td class='t-title'>所属企业</td>
        <td class='d-content'>{{ detailData.parentName }}</td>
      </tr>
      <tr>
        <td class='t-title'>企业类型</td>
        <td class='d-content'>{{ detailData.orgIdType }}</td>
        <td class='t-title'>单位类型</td>
        <td class='d-content'>{{ detailData.organizeTypeName ? detailData.organizeTypeName : '---' }}</td>
      </tr>

      <tr>
        <td class='t-title'>单位性质</td>
        <td class='d-content'>{{ detailData.type }}</td>
        <td class='t-title'>注册县区</td>
        <td class='d-content'>{{ detailData.divisionName }}</td>
      </tr>
      <tr>
        <td class='t-title'>证件类型</td>
        <td class='d-content'>{{ detailData.idType }}</td>
        <td class='t-title'>证件号</td>
        <td class='d-content'>{{ detailData.tcol }}</td>
      </tr>
      <tr>
        <td class='t-title'>法定代表</td>
        <td class='d-content'>{{ detailData.chairMan }}</td>
        <td class='t-title'>注册资本(万元)</td>
        <td class='d-content'>{{ detailData.capital }}</td>
      </tr>
      <tr>
        <td class='t-title'>成立时间</td>
        <td class='d-content'>{{ detailData.regDate }}</td>
        <td class='t-title'>营业期限</td>
        <td class='d-content'>{{ detailData.certDate }}</td>
      </tr>
      <tr>
        <td class='t-title'>所属网格区域</td>
        <td class='d-content'>{{ detailData.mapRegion }}</td>
        <td class='t-title'>注册地址</td>
        <td class='d-content'>{{ detailData.address }}</td>
      </tr>
      <tr>
        <td class='t-title'>企业联系电话</td>
        <td class='d-content'>{{ detailData.phoneNo }}</td>
        <td class='t-title'>企业联系地址</td>
        <td class='d-content'>{{ detailData.officeAddress }}</td>
      </tr>
      <tr>
        <td class='t-title'>经营状态</td>
        <td class='d-content'>{{ detailData.businessSituation }}</td>
        <td class='t-title'>经营范围</td>
        <td class='d-content'>{{ detailData.scope }}</td>
      </tr>
      </tbody>
    </table>
    <el-row>
      <otherInfo
        v-if='detailData.orgId'
        :orgId='detailData.orgId'
        :fullname='fname'
      ></otherInfo>
    </el-row>
  </div>
  <!-- 添加模板 -->
  <addModel
    title='企业信息采集反馈'
    @response='response'
    ref='addModelRef'
  ></addModel>
</template>

<script setup>
import { ElMessage } from 'element-plus'
import { getEnterpriseDetail } from '@/api/enterprise'
import { ref, onMounted, nextTick } from 'vue'
import { useRoute } from 'vue-router'
import QrcodeVue from 'qrcode.vue'
import otherInfo from '@/components/EnterpriseInfoRelated'
import addModel from './addModel.vue'
import { tCreditEnterpriseRef } from '@/api/situation.js'

const addModelRef = ref()

// 打开企业信息采集情况反应
function add() {
  nextTick(() => {
    addModelRef.value.openModel()
  })
}

// 添加表单回执
async function response(e) {
  // console.log(detailData.value)
  const obj = {
    attachment: e.fileList,
    auditSteps: 1,
    name: e.form.name,
    phone: e.form.phone,
    situation: e.form.remark,
    companyId: detailData.value.orgId
  }
  try {
    const res = await tCreditEnterpriseRef(obj)
    ElMessage.success('创建成功！')
  } catch (error) {
    ElMessage.success(error)
  }
}

const fname = ref()
const detailData = ref({})
const route = useRoute()

const getDetail = async () => {
  const result = await getEnterpriseDetail({
    orgName: fname.value
  })
  detailData.value = result
}
onMounted(() => {
  fname.value = route.params.fname

  getDetail()
})

/**
 * 二维码生成配置
 */
const qrcodeSize = ref(100)
</script>

<style lang='scss'>
.title {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.list-wrapper {
  position: relative;
  padding: 10px;
  border: solid 1px #e4e7ed;
  font-size: 13px;

  h1 {
    margin: 0px 0 10px;
  }

  .sub-type-warp {
    margin: 0px 0 10px;
  }
}

.detail-table {
  border-collapse: collapse;
  margin: 0 auto;
  border: 1px #e4e7ed solid;
  border-width: 1px 0 0 1px;

  .t-title {
    padding-left: 10px;
    line-height: 30px;
    width: 10%;
    background: #f0f7fc;
    font-weight: 600;
    border: 1px #e4e7ed solid;
    border-width: 0 1px 1px 0;
  }

  .d-content {
    padding-left: 10px;
    line-height: 28px;
    min-width: 350px;
    border: 1px #e4e7ed solid;
    border-width: 0 1px 1px 0;
  }
}

.qr-img {
  position: absolute;
  right: 10px;
  top: 60px;
  padding: 10px;
  height: 120px;
  width: 120px;
  z-index: 888;
  border: 1px #e4e7ed solid;
  background-color: #fff;
  box-shadow: 1px 1px 2px #e2e2e2;
}
</style>
