<template>
  <div class="other-info-warp">
    <el-row :gutter="20">
      <!-- 企业推荐房源 -->
      <el-col :span="8">
        <h2>企业推荐房源</h2>
        <div v-if="houseData.length === 0" class="no-data">--暂无数据--</div>
        <el-card
          v-else
          class="box-card"
          shadow="hover"
          v-for="(h, index) in houseData"
          :key="index"
          :body-style="{ padding: '10px' }"
        >
          <el-row :gutter="20" @click="houseShowDetail(h)">
            <el-col :span="8">
              <el-image
                style="width: 120px"
                :src="h.houseImg"
                referrer="no-referrer|origin|unsafe-url"
              >
                <template #error>
                  <div class="image-slot" style="height: 100%">
                    <div
                      style="
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        opacity: 0.8;
                        background: #ecf5ff;
                        flex-direction: column;
                        color: #5298f5;
                        font-size: 14px;
                      "
                    >
                      <el-image
                        class="image"
                        style="width: 120px; height: 80px"
                        src="https://pc.nnfwzl.com/rent-house/rent-platform-web/default.png"
                      />
                      图片正在拍摄中
                    </div>
                  </div>
                </template>
              </el-image>
            </el-col>
            <el-col :span="16" class="house-info">
              <div class="name">
                {{ h.rentType === 2 ? '分租' : '整租' }} ·

                {{ h.street }}·{{ h.communityName }}
              </div>
              <div class="aera">房源面积: {{ h.buildingArea }}㎡</div>
              <div class="data-time">上架时间: {{ h.upAt }}</div>
              <div class="price">房源租金: {{ h.rentPrice }}元/月</div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <!-- 企业门店 & 评论 -->
      <el-col :span="9">
        <h2>企业门店</h2>
        <div v-if="shopData.length === 0" class="no-data">--暂无数据--</div>
        <el-card
          v-else
          shadow="hover"
          class="box-card"
          v-for="(s, index) in shopData"
          :key="index"
          :body-style="{ padding: '10px' }"
        >
          <el-row
            :gutter="20"
            @click="storeDetail(s.orgId)"
            style="cursor: pointer"
          >
            <el-col :span="5">
              <el-image
                style="width: 75px"
                src="http://img0.baidu.com/it/u=1580287918,1662928404&fm=253&app=138&f=JPEG?w=500&h=586"
              >
                <template #error>
                  <div class="image-slot">
                    <el-icon><icon-picture /></el-icon>
                  </div>
                </template>
              </el-image>
            </el-col>
            <el-col :span="18" class="agent-info">
              <div class="fname">
                <el-tooltip
                  :ref="'tip-' + index"
                  class="box-item"
                  effect="dark"
                  :content="s.fullName"
                  placement="top-start"
                >
                  <span style="cursor: pointer"> {{ s.fullName }}</span>
                </el-tooltip>
              </div>
              <div class="post">负责人: {{ s.chairMan }}</div>
              <div class="company">地址: {{ s.address }}</div>
            </el-col>
          </el-row>
        </el-card>
        <el-pagination
          v-if="shopData.length"
          :small="true"
          style="text-align: right"
          :page-size="shopPage.pageSize"
          background
          layout="prev, pager, next"
          :total="shopTotal"
          @current-change="shopCurrentChange"
        />
        <h2>企业评论</h2>
        <div v-if="commentData.length === 0" class="no-data">--暂无数据--</div>
        <el-card
          v-else
          class="box-card"
          shadow="hover"
          v-for="(cm, index) in commentData"
          :key="index"
          :body-style="{ padding: '10px' }"
        >
          <el-row :gutter="20" justify="space-between">
            <el-col :span="5">
              <el-image
                style="width: 75px"
                src="http://img0.baidu.com/it/u=1580287918,1662928404&fm=253&app=138&f=JPEG?w=500&h=586"
              >
                <template #error>
                  <div class="image-slot">
                    <el-icon><icon-picture /></el-icon>
                  </div>
                </template>
              </el-image>
            </el-col>
            <el-col :span="18" class="agent-info">
              <div class="aera">评论者: {{ cm.commentname }}</div>
              <div class="data-time">评论内容: {{ cm.commentinfo }}</div>
              <div class="data-time">评论时间: {{ cm.createdate }}</div>
            </el-col>
          </el-row>
        </el-card>
        <el-pagination
          v-if="commentData.length"
          :small="true"
          style="text-align: right"
          :page-size="commentPage.pageSize"
          background
          layout="prev, pager, next"
          :total="commentTotal"
          @current-change="commentCurrentChange"
        />
      </el-col>
      <!-- 经纪人 -->
      <el-col :span="7">
        <h2>经纪人</h2>

        <div v-if="agentData.length === 0" class="no-data">--暂无数据--</div>
        <el-card
          v-else
          class="box-card"
          shadow="hover"
          v-for="(ag, index) in agentData"
          :key="index"
          :body-style="{ padding: '10px' }"
        >
          <el-row
            :gutter="20"
            justify="space-between"
            @click="agentDetail(ag.id)"
            style="cursor: pointer"
          >
            <el-col :span="5">
              <el-image
                style="width: 75px"
                src="http://img0.baidu.com/it/u=1580287918,1662928404&fm=253&app=138&f=JPEG?w=500&h=586"
              >
                <template #error>
                  <div class="image-slot">
                    <el-icon><icon-picture /></el-icon>
                  </div>
                </template>
              </el-image>
            </el-col>
            <el-col :span="18" class="agent-info">
              <div class="name">{{ ag.name }}</div>
              <div class="post">职务: {{ ag.post }}</div>
              <div class="company">就职企业: {{ fullname }}</div>
            </el-col>
          </el-row>
        </el-card>
        <el-pagination
          v-if="agentData.length"
          :small="true"
          style="text-align: right"
          :page-size="agentPage.pageSize"
          background
          layout="prev, pager, next"
          :total="agentTotal"
          @current-change="agentCurrentChange"
        />
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from 'vue'
import {
  getEnterpriseHouseFreeList,
  getEnterpriseShop,
  getEnterpriseComment,
  getEnterpriseAgent
} from '@/api/enterprise'
import { useRouter } from 'vue-router'
export default defineComponent({
  props: ['orgId', 'fullname'],
  setup(props, context) {
    const houseData = ref([
      {
        communityName: '第一大道·尚城街区',
        locationName: '民族大道',
        rentType: 2,
        houseOther:
          'https://pc.nnfwzl.com/rent-house/ae53a437-7590-4710-a2ce-a13b3f85dd05/20220620/1655690790-%E9%9D%92%E6%9C%A8%E8%88%8D%E5%A4%A7%E5%8E%85.jpg',
        space: 114.56,
        upAt: '2022-08-03 17:34:00',
        rentPrice: 600
      }
    ])
    const noImg = require('@/assets/house_noimg.png')
    const shopData = ref([])
    const shopTotal = ref(0)
    const shopPage = ref({
      pageNo: 1,
      pageSize: 3
    })
    const commentData = ref([])
    const agentData = ref([])
    const router = useRouter()
    // 获取房源数据
    const getHousing = async () => {
      if (props.orgId) {
        const result = await getEnterpriseHouseFreeList({
          companyName: props.fullname,
          type: '2',
          pageNo: 1,
          pageSize: 4
        })
        const tempData = []

        if (result.records) {
          result.records.records.map((item) => {
            item.houseImgArr = JSON.parse(item.houseOther)
            item.houseImg = result.minioUrl + item.houseImgArr[0].src
            tempData.push(item)
          })
          console.log(tempData)
          houseData.value = tempData
        } else {
          houseData.value = houseData.value.concat(result.records)
        }
      }
    }

    // 获取门店数据
    const getShop = async () => {
      if (props.orgId) {
        const result = await getEnterpriseShop({
          parentId: props.orgId,
          pageNo: shopPage.value.pageNo,
          pageSize: shopPage.value.pageSize
        })
        shopTotal.value = result.total
        shopData.value = result.records
      }
    }
    const shopCurrentChange = (val) => {
      shopPage.value.pageNo = val
      getShop()
    }
    onMounted(() => {
      // 门店列表
      shopPage.value.pageNo = 1
      getShop()

      // 评论列
      commentPage.value.pageNo = 1
      getComment()

      // 经纪人
      agentPage.value.pageNo = 1
      getAgent()

      // 推荐房源
      getHousing()
    })

    const commentTotal = ref(0)
    const commentPage = ref({
      pageNo: 1,
      pageSize: 10
    })
    const commentCurrentChange = (val) => {
      commentPage.value.pageNo = val
      getComment()
    }

    // 获取评论数据
    const getComment = async () => {
      if (props.orgId) {
        const result = await getEnterpriseComment({
          id: props.orgId,
          pageNo: commentPage.value.pageNo,
          pageSize: commentPage.value.pageSize,
          type: 'comment'
        })
        commentTotal.value = result.total
        commentData.value = result.records
      }
    }

    const agentTotal = ref(0)
    const agentPage = ref({
      pageNo: 1,
      pageSize: 5
    })
    // 获取经纪人数据
    const getAgent = async () => {
      if (props.orgId) {
        const result = await getEnterpriseAgent({
          orgId: props.orgId,
          pageNo: agentPage.value.pageNo,
          pageSize: agentPage.value.pageSize
        })
        agentTotal.value = result.total
        agentData.value = result.records
      }
    }

    const agentCurrentChange = (val) => {
      agentPage.value.pageNo = val
      getAgent()
    }

    /**
     * 门店详情
     */
    const storeDetail = (orgId) => {
      router.push({
        path: `/enterpriseInfo/store/${orgId}`
      })
    }
    /**
     * 经纪人详情
     */
    const agentDetail = (userId) => {
      router.push({
        path: `/enterpriseInfo/agent/${userId}`
      })
    }
    /**
     * 房源详情
     */
    const houseShowDetail = (item) => {
      if (item.source) {
        window.open(item.link_address, '_blank')
      } else {
        const routeUrl = router.resolve({
          name: 'houseDetail',
          query: { id: item.houseId }
        })
        window.open(routeUrl.href, '_blank')
      }
    }
    return {
      getHousing,
      getShop,
      getComment,
      getAgent,
      houseData,
      commentData,
      agentData,
      shopData,
      storeDetail,
      agentDetail,
      houseShowDetail,
      noImg,
      shopTotal,
      shopPage,
      shopCurrentChange,
      commentTotal,
      commentPage,
      commentCurrentChange,
      agentTotal,
      agentPage,
      agentCurrentChange
    }
  }
})
</script>
<style lang="scss" scoped>
.other-info-warp {
  width: 100%;
  margin: 20px auto;
  h2 {
    line-height: 45px;
  }
  .no-data {
    line-height: 50px;
  }
  .box-card {
    margin: 5px 0 10px;
    min-height: 121px;
    .agent-info {
      div {
        line-height: 24px;
      }
      .fname {
        width: 320px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 16px;
        font-weight: 500;
      }
      .name {
        font-size: 16px;
        font-weight: 500;
      }
      .name:hover {
        color: #409eff;
      }
    }
    .house-info {
      div {
        line-height: 24px;
      }
      .name {
        width: 320px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 16px;
        font-weight: 500;
      }
      .name:hover {
        color: #409eff;
      }
      .price {
        color: #db4c3f;
        font-weight: 500;
      }
    }
  }
}
</style>
