import request from '@/utils/request'
const baseURL = process.env.VUE_APP_BASE_API_ENTERPRISE

/**
 * 获取企业信息
 */

export const getEnterpriseList = (data) => {
  return request({
    url: '/enterPrise/searChOrganize',
    method: 'POST',
    data,
    baseURL
  })
}
/**
 * 成交量排行
 */

export const getEnterpriseHtRank = (data) => {
  return request({
    url: '/icount/countHtRank',
    method: 'POST',
    data
  })
}
/**
 * 挂牌量排行
 */

export const getEnterpriseUpRank = (data) => {
  return request({
    url: '/icount/countUpRank',
    method: 'POST',
    data
  })
}

/**
 * 根据企业名称获取企业信息
 */
export const getEnterpriseDetail = (data) => {
  return request({

    url: '/grid/getOrgInfoByName',
    params: data,
    baseURL

  })
}
/**
 * 根据企业ID获取企业信息
 */
export const getIdEnterpriseDetail = (data) => {
  return request({
    url: '/grid/getOrganizeById',
    params: data,
    baseURL
  })
}

/**
 * 根据企业id查询所属该企业发布的房源
 */
export const getEnterpriseHousing = (data) => {
  return request({
    url: '/grid/selectHousingInfoByOrgId',
    params: data,
    baseURL
  })
}

/**
 * 根据企业名称查询所属该企业发布的房源(租赁交易服务-获取免登录房源列表 )
 */
export const getEnterpriseHouseFreeList = (data) => {
  return request({
    url: '/free/houseFreeList',
    method: 'POST',
    data
  })
}
/**
 * 根据企业ID查询该企业从业人员列表
 */
export const getEnterpriseAgent = (data) => {
  return request({
    url: '/grid/getUserByOrgId',
    params: data,
    baseURL
  })
}
/**
 * 根据企业编号查询所属该企业的门店和加盟店 */
export const getEnterpriseShop = (data) => {
  return request({
    url: '/grid/getSuborganizeById',
    params: data,
    baseURL
  })
}
/**
 * 获取企业评论和投诉列表
 */
export const getEnterpriseComment = (data) => {
  return request({
    url: '/grid/getCommentList',
    params: data,
    baseURL
  })
}

/**
 * 经纪人详情
 * @param {userId} 经纪人ID
 */
export const getAgentDetail = (data) => {
  return request({
    url: '/grid/getUserById',
    params: data,
    baseURL
  })
}

/**
 * 门店详情
 * @param {branchId} 门店ID
 */
export const getShopDetail = (data) => {
  return request({
    url: '/grid/getBranchById',
    params: data,
    baseURL
  })
}
